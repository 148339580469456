import React from "react";
// import ButtonSolid from "../../components/Button/ButtonSolid";
import { StaticImage } from "gatsby-plugin-image";

const AboutUsSection2 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className=" relative  w-full overflow-hidden  py-24 md:bg-transparent ">
        <div className="absolute bottom-0 left-0  h-full w-full md:block ">
          <StaticImage
            src="../../images/6.0 Reviews/Hero-Desktop.jpg"
            loading="eager"
            placeholder="none"
            objectPosition="fixed"
            imgClassName="object-top"
          />
        </div>

        <div className="container relative mx-auto mt-[15px] md:pt-[200px] text-center">
          <div className="grid gap-y-10">
            <div className=" text-center">
              <h2 className="mx-auto text-center">Our Mission</h2>
              <p className="lg:max-w-[60%] mx-auto text-center">
                HarborView is dedicated to offering compassionate,
                individualized care and support services for those living with
                Alzheimer’s disease or other types of dementia. We believe in
                the power and strength of all aspects of life—physical, social,
                and spiritual. Our well-trained and considerate San Diego elder
                care staff works around the clock to care for residents in our
                charming and intimate home.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsSection2;
