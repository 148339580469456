import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const AboutUsSection3 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-18">
            <div className="order-2 md:order-1">
              <h2>{sectionTitle}</h2>
              <p >
                HarborView is owned and operated by Jeffrey Settineri, a San
                Diegan who has dedicated his life to caring for our community’s
                elderly. His interest in caregiving began as a child when he
                watched his mother care for several elderly family members in a
                small home in North Park.
              </p>
              <p>
                As an adult, his parents supported his dream of creating a
                community of care. Today, he and his family own and operate
                HarborView and three other assisted living homes in greater San
                Diego.
              </p>
            </div>
            <div className="order-1 md:order-2 mx-auto">
              <StaticImage
                src="../../images/4.0 About/2.0 Meet Jeff Heading.jpg"
                alt="Top Alzheimer’s & Dementia Care Serving Encinitas & North County, CA"
                width={475}
                loading="eager"
                placeholder="none"
                imgClassName="rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsSection3;
