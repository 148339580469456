import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";
// import ButtonGhost from "../../components/Button/ButtonGhost";
const AboutUsSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <section className="bg-tertiary-50 relative md:pt-0">
      <div className="container hidden md:block">
        <div className="grid gap-y-12 gap-x-[20px] md:grid-cols-2">
          <div className="md:py-24 lg:py-36 lg:pr-20">
            <h1>{sectionTitle}</h1>
            <p>
              HarborView Senior Assisted Living in San Diego changed ownership
              in 2016 and is family owned and operated. Our caring,
              professional, and well-trained staff is devoted to providing a
              loving, supportive environment for residents and their families.
              Respect, dignity, compassion, trust, and safety are as important
              to us as they are to you.
            </p>
            <p>
              Our 1927 Italian Renaissance home offers unparalleled charm and
              beauty with a breathtaking view, luscious gardens complete with
              fountains, protected pathways, and an expansive patio overlooking
              the city and San Diego Bay. The renovations and modern amenities
              we’ve added to this historic property complement its past and
              increase the comfort for our residents and their families.
            </p>
            <ButtonSolid
              modal="modal-contact"
              text="Contact Us"
              className={
                "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
              }
            />
          </div>
          <div className="relative md:h-full md:py-24 ">
            <div className="">
              <StaticImage
                src="../../images/4.0 About/1.0 About-hero.png"
                loading="eager"
                placeholder="none"
                layout="constrained"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="grid gap-y-12 md:grid-cols-2 ">
          <div className="md:py-24 lg:py-36 ">
            <h1>{sectionTitle}</h1>
            <p>
              HarborView Senior Assisted Living in San Diego changed ownership
              in 2016 and is family owned and operated. Our caring,
              professional, and well-trained staff is devoted to providing a
              loving, supportive environment for residents and their families.
              Respect, dignity, compassion, trust, and safety are as important
              to us as they are to you.
            </p>
            <p>
              Our 1927 Italian Renaissance home offers unparalleled charm and
              beauty with a breathtaking view, luscious gardens complete with
              fountains, protected pathways, and an expansive patio overlooking
              the city and San Diego Bay. The renovations and modern amenities
              we’ve added to this historic property complement its past and
              increase the comfort for our residents and their families.
            </p>

            <ButtonSolid
              modal="modal-contact"
              text="Contact Us"
              className={
                "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection1;
